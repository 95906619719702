export const dummyPosts = [
  {
    id: 1,
    title: "Prayer for Health and Recovery",
    content: "Please pray for my mother who is currently in the hospital recovering from surgery. She's been struggling with pain but staying strong in faith.",
    author: "Sarah Johnson",
    date: "2024-01-23",
    prayerCount: 24,
    tags: ["Health", "Family"],
    comments: [
      {
        id: 1,
        author: "John Smith",
        content: "Praying for your mother's swift recovery. Stay strong! 🙏",
        date: "2024-01-23"
      }
    ]
  },
  {
    id: 2,
    title: "Guidance for Career Decision",
    content: "I'm at a crossroads in my career and need wisdom for an important decision. Please pray for God's clear direction in this matter.",
    author: "Michael Chen",
    date: "2024-01-22",
    prayerCount: 15,
    tags: ["Career", "Guidance"],
    comments: [
      {
        id: 1,
        author: "Emily Davis",
        content: "May God give you clarity and peace in this decision. 🙏",
        date: "2024-01-22"
      }
    ]
  },
  {
    id: 3,
    title: "Prayer for Marriage Restoration",
    content: "Requesting prayers for my marriage. Going through a difficult time and seeking God's intervention for healing and reconciliation.",
    author: "David Wilson",
    date: "2024-01-21",
    prayerCount: 42,
    tags: ["Marriage", "Relationships"],
    comments: [
      {
        id: 1,
        author: "Rachel Thompson",
        content: "Standing with you in prayer for restoration. God is faithful! ❤️",
        date: "2024-01-21"
      }
    ]
  },
  {
    id: 4,
    title: "Financial Breakthrough Needed",
    content: "Please join me in praying for financial provision. Recently lost my job and trying to support my family while searching for new opportunities.",
    author: "Lisa Martinez",
    date: "2024-01-20",
    prayerCount: 31,
    tags: ["Finance", "Employment"],
    comments: [
      {
        id: 1,
        author: "James Wilson",
        content: "Praying for doors to open and God's provision in your life. 🙏",
        date: "2024-01-20"
      }
    ]
  },
  {
    id: 5,
    title: "Prayer for Child's Education",
    content: "My son is struggling with learning difficulties at school. Requesting prayers for his studies and for wisdom for his teachers.",
    author: "Amanda Brown",
    date: "2024-01-19",
    prayerCount: 18,
    tags: ["Education", "Children"],
    comments: [
      {
        id: 1,
        author: "Mark Anderson",
        content: "Praying for your son's academic journey. May God grant him understanding and confidence. 📚🙏",
        date: "2024-01-19"
      }
    ]
  }
];
