// API Configuration
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const apiConfig = {
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

export const getAuthHeader = () => {
  const token = localStorage.getItem('token');
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export default apiConfig;
