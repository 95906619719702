import React, { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { Button } from "./ui/button";
import { Textarea } from "./ui/textarea";
import { Heart, MessageCircle, Calendar, Send } from "lucide-react";
import { Avatar, AvatarImage, AvatarFallback } from "./ui/avatar";
import { ScrollArea } from "./ui/scroll-area";
import { Separator } from "./ui/separator";

export function PrayerRequestDetail({ post, open, onOpenChange, onPrayerClick, onCommentSubmit }) {
  const [comment, setComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!comment.trim()) return;

    setIsSubmitting(true);
    try {
      await onCommentSubmit(post.id, comment);
      setComment("");
    } catch (error) {
      console.error("Error submitting comment:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(part => part[0])
      .join('')
      .toUpperCase();
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px] max-h-[90vh] flex flex-col">
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold">{post.title}</DialogTitle>
          <div className="flex items-center justify-between text-sm text-muted-foreground mt-2">
            <div className="flex items-center space-x-2">
              <Avatar className="h-6 w-6">
                <AvatarFallback>{getInitials(post.author)}</AvatarFallback>
              </Avatar>
              <span>{post.author}</span>
            </div>
            <div className="flex items-center">
              <Calendar className="h-4 w-4 mr-1" />
              <time>{formatDate(post.date)}</time>
            </div>
          </div>
        </DialogHeader>

        <ScrollArea className="flex-1 pr-4">
          {/* Main Content */}
          <div className="space-y-4">
            <p className="text-foreground/90 whitespace-pre-wrap">{post.content}</p>

            {/* Images */}
            {post.images && post.images.length > 0 && (
              <div className="grid grid-cols-2 gap-2">
                {post.images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Prayer request image ${index + 1}`}
                    className="w-full rounded-md cursor-pointer hover:opacity-90 transition-opacity"
                    onClick={() => window.open(image, '_blank')}
                  />
                ))}
              </div>
            )}

            {/* Tags */}
            {post.tags && post.tags.length > 0 && (
              <div className="flex flex-wrap gap-2">
                {post.tags.map((tag, index) => (
                  <span
                    key={index}
                    className="bg-muted px-2 py-1 rounded-md text-xs text-muted-foreground"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            )}

            {/* Prayer Count */}
            <div className="flex items-center space-x-4">
              <Button
                variant="ghost"
                size="sm"
                className="flex items-center space-x-1 hover:text-primary"
                onClick={() => onPrayerClick(post.id)}
              >
                <Heart className={`h-4 w-4 ${post.hasPrayed ? 'fill-primary text-primary' : ''}`} />
                <span>{post.prayerCount} Prayers</span>
              </Button>
            </div>

            <Separator className="my-4" />

            {/* Comments Section */}
            <div className="space-y-4">
              <h3 className="font-semibold">Comments ({post.comments.length})</h3>
              <div className="space-y-4">
                {post.comments.map((comment, index) => (
                  <div key={index} className="flex space-x-3">
                    <Avatar className="h-8 w-8">
                      <AvatarFallback>{getInitials(comment.author)}</AvatarFallback>
                    </Avatar>
                    <div className="flex-1 space-y-1">
                      <div className="flex items-center justify-between">
                        <span className="text-sm font-medium">{comment.author}</span>
                        <span className="text-xs text-muted-foreground">
                          {formatDate(comment.date)}
                        </span>
                      </div>
                      <p className="text-sm text-foreground/90">{comment.content}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </ScrollArea>

        {/* Comment Input */}
        <div className="mt-4 pt-4 border-t">
          <form onSubmit={handleCommentSubmit} className="flex space-x-2">
            <Textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Write a comment..."
              className="min-h-[60px]"
              disabled={isSubmitting}
            />
            <Button
              type="submit"
              size="icon"
              disabled={isSubmitting || !comment.trim()}
              className="shrink-0"
            >
              <Send className="h-4 w-4" />
            </Button>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
}
