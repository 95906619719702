import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from "./ui/button";
import { LogIn, LogOut, UserPlus, Home as HomeIcon, PlusCircle } from "lucide-react";
import { ThemeToggle } from './ui/theme-toggle';
import { useAuth } from '../contexts/auth-context';

export default function Navbar() {
  const { isAuthenticated, logout } = useAuth();

  return (
    <header className="bg-muted/50 sticky top-0 z-50 w-full border-b">
      <div className="container flex h-16 items-center">
        <Link to="/" className="flex items-center space-x-2">
          <HomeIcon className="h-6 w-6 text-muted-foreground" />
          <span className="text-xl font-semibold tracking-tight">CryOutNow</span>
        </Link>
        
        <nav className="ml-auto flex items-center gap-4">
          <Button variant="ghost" className="text-muted-foreground hover:text-foreground" asChild>
            <Link to="/prayer-requests">Prayer Requests</Link>
          </Button>

          {isAuthenticated ? (
            <>
              <Button variant="ghost" className="text-muted-foreground hover:text-foreground" asChild>
                <Link to="/create-prayer-request">
                  <PlusCircle className="mr-2 h-4 w-4" />
                  Create Request
                </Link>
              </Button>
              <Button 
                variant="ghost" 
                className="text-muted-foreground hover:text-foreground"
                onClick={logout}
              >
                <LogOut className="mr-2 h-4 w-4" />
                Logout
              </Button>
            </>
          ) : (
            <>
              <Button variant="ghost" className="text-muted-foreground hover:text-foreground" asChild>
                <Link to="/login">
                  <LogIn className="mr-2 h-4 w-4" />
                  Login
                </Link>
              </Button>
              <Button variant="default" className="bg-background text-foreground hover:bg-muted" asChild>
                <Link to="/register">
                  <UserPlus className="mr-2 h-4 w-4" />
                  Register
                </Link>
              </Button>
            </>
          )}
          <ThemeToggle />
        </nav>
      </div>
    </header>
  );
}
