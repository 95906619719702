import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Login from './components/Login';
import Register from './components/Register';
import PrayerRequests from './components/PrayerRequests';
import CreatePrayerRequest from './components/CreatePrayerRequest';
import { ThemeProvider } from './components/theme-provider';
import { AuthProvider } from './contexts/auth-context';

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <div className="min-h-screen bg-background font-sans antialiased">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/prayer-requests" element={<PrayerRequests />} />
            <Route path="/create-prayer-request" element={<CreatePrayerRequest />} />
          </Routes>
        </div>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
