import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from "./ui/button";
import { Card, CardHeader, CardContent, CardFooter } from "./ui/card";
import { LogIn, UserPlus, Home as HomeIcon, Heart, MessageCircle, Calendar } from "lucide-react";
import { dummyPosts } from '../data/dummyPosts';
import { PrayerRequestDetail } from './PrayerRequestDetail';

export default function Home() {
  const [selectedPost, setSelectedPost] = useState(null);
  const [posts, setPosts] = useState(dummyPosts.map(post => ({ ...post, hasPrayed: false })));

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handlePrayerClick = (postId) => {
    setPosts(posts.map(post => {
      if (post.id === postId) {
        return {
          ...post,
          prayerCount: post.hasPrayed ? post.prayerCount - 1 : post.prayerCount + 1,
          hasPrayed: !post.hasPrayed
        };
      }
      return post;
    }));
  };

  const handleCommentSubmit = async (postId, commentText) => {
    // TODO: Implement API call to submit comment
    const newComment = {
      id: Math.random().toString(36).substr(2, 9),
      author: "Current User", // TODO: Get from auth
      content: commentText,
      date: new Date().toISOString()
    };

    setPosts(posts.map(post => {
      if (post.id === postId) {
        return {
          ...post,
          comments: [...post.comments, newComment]
        };
      }
      return post;
    }));
  };

  return (
    <div className="min-h-screen bg-background">
      {/* Hero Section */}
      <section className="py-12 px-4">
        <div className="max-w-7xl mx-auto text-center">
          <h1 className="text-4xl md:text-6xl font-bold tracking-tighter mb-4">
            Welcome to CryOutNow
          </h1>
          <p className="text-xl text-muted-foreground mb-8 mx-auto max-w-[700px]">
            A place to share and support prayer requests
          </p>
          <div className="flex gap-4 justify-center mb-12">
            <Button size="lg" className="bg-background text-foreground hover:bg-muted" asChild>
              <Link to="/prayer-requests">View Prayer Requests</Link>
            </Button>
            <Button size="lg" variant="outline" className="text-muted-foreground hover:text-foreground" asChild>
              <Link to="/register">Get Started</Link>
            </Button>
          </div>
        </div>
      </section>

      {/* Prayer Requests Section */}
      <section className="py-8 px-4">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-2xl font-semibold tracking-tight mb-6">Recent Prayer Requests</h2>
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            {posts.slice(0, 6).map((post) => (
              <Card 
                key={post.id} 
                className="flex flex-col cursor-pointer hover:shadow-md transition-shadow"
                onClick={() => setSelectedPost(post)}
              >
                <CardHeader>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      <span className="font-semibold">{post.author}</span>
                    </div>
                    <div className="flex items-center text-muted-foreground text-sm">
                      <Calendar className="h-4 w-4 mr-1" />
                      <time>{formatDate(post.date)}</time>
                    </div>
                  </div>
                  <h3 className="text-lg font-semibold mt-2">{post.title}</h3>
                </CardHeader>
                <CardContent>
                  <p className="text-muted-foreground">
                    {post.content.length > 150 
                      ? `${post.content.substring(0, 150)}...` 
                      : post.content}
                  </p>
                  {post.images && post.images.length > 0 && (
                    <div className="mt-4 grid grid-cols-3 gap-2">
                      {post.images.map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          alt={`Prayer request image ${index + 1}`}
                          className="w-full h-24 object-cover rounded-md cursor-pointer hover:opacity-90 transition-opacity"
                          onClick={(e) => {
                            e.stopPropagation();
                            window.open(image, '_blank');
                          }}
                        />
                      ))}
                    </div>
                  )}
                  {post.tags.length > 0 && (
                    <div className="flex gap-2 mt-4 flex-wrap">
                      {post.tags.map((tag, index) => (
                        <span
                          key={index}
                          className="bg-muted px-2 py-1 rounded-md text-xs text-muted-foreground"
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  )}
                </CardContent>
                <CardFooter className="mt-auto border-t pt-4">
                  <div className="flex items-center justify-between w-full text-muted-foreground">
                    <button 
                      className="flex items-center space-x-1 hover:text-foreground transition-colors"
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePrayerClick(post.id);
                      }}
                    >
                      <Heart className={`h-4 w-4 ${post.hasPrayed ? 'fill-primary text-primary' : ''}`} />
                      <span>{post.prayerCount}</span>
                    </button>
                    <button className="flex items-center space-x-1 hover:text-foreground transition-colors">
                      <MessageCircle className="h-4 w-4" />
                      <span>{post.comments.length}</span>
                    </button>
                  </div>
                </CardFooter>
              </Card>
            ))}
          </div>
          <div className="flex justify-center mt-8">
            <Button variant="outline" size="lg" asChild>
              <Link to="/prayer-requests">View All Prayer Requests</Link>
            </Button>
          </div>
        </div>
      </section>

      {selectedPost && (
        <PrayerRequestDetail
          post={selectedPost}
          open={!!selectedPost}
          onOpenChange={(open) => !open && setSelectedPost(null)}
          onPrayerClick={handlePrayerClick}
          onCommentSubmit={handleCommentSubmit}
        />
      )}
    </div>
  );
}
