import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardContent, CardFooter } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Heart, MessageCircle, Calendar, Plus } from "lucide-react";
import axios from 'axios';
import apiConfig, { getAuthHeader } from '../config/api.js';
import { CreatePrayerRequest } from './CreatePrayerRequest';
import { PrayerRequestDetail } from './PrayerRequestDetail';
import { useAuth } from '../contexts/auth-context';

export default function PrayerRequests() {
  const { isAuthenticated } = useAuth();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPrayerRequests();
  }, []);

  const fetchPrayerRequests = async () => {
    try {
      const response = await axios.get('/api/prayer-requests', apiConfig);
      setPosts(response.data.map(post => ({ ...post, hasPrayed: false })));
    } catch (error) {
      console.error('Error fetching prayer requests:', error);
      setError('Failed to load prayer requests');
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleCreatePrayerRequest = async (formData) => {
    try {
      const data = {
        title: formData.get("title"),
        content: formData.get("content"),
        tags: formData.get("tags").split(",").map(tag => tag.trim()).filter(Boolean),
        images: JSON.parse(formData.get("images") || "[]")
      };

      const config = {
        ...apiConfig,
        headers: {
          ...apiConfig.headers,
          ...getAuthHeader()
        }
      };

      const response = await axios.post('/api/prayer-requests', data, config);
      setPosts([response.data, ...posts]);
      setIsDialogOpen(false);
    } catch (error) {
      console.error("Error creating prayer request:", error);
      throw new Error(error.response?.data?.message || 'Failed to create prayer request');
    }
  };

  const handlePrayerClick = async (postId) => {
    try {
      const config = {
        ...apiConfig,
        headers: {
          ...apiConfig.headers,
          ...getAuthHeader()
        }
      };

      const response = await axios.post(`/api/prayer-requests/${postId}/pray`, {}, config);
      setPosts(posts.map(post => {
        if (post.id === postId) {
          return {
            ...post,
            prayerCount: response.data.prayerCount,
            hasPrayed: response.data.hasPrayed
          };
        }
        return post;
      }));
    } catch (error) {
      console.error('Error updating prayer count:', error);
      alert('Failed to update prayer count');
    }
  };

  const handleCommentSubmit = async (postId, commentText) => {
    try {
      const config = {
        ...apiConfig,
        headers: {
          ...apiConfig.headers,
          ...getAuthHeader()
        }
      };

      const response = await axios.post(`/api/prayer-requests/${postId}/comments`, {
        content: commentText
      }, config);

      setPosts(posts.map(post => {
        if (post.id === postId) {
          return {
            ...post,
            comments: [...post.comments, response.data]
          };
        }
        return post;
      }));
    } catch (error) {
      console.error('Error submitting comment:', error);
      throw new Error(error.response?.data?.message || 'Failed to submit comment');
    }
  };

  return (
    <div className="min-h-screen bg-background py-8">
      <div className="max-w-7xl mx-auto px-4">
        {error && (
          <div className="bg-destructive/15 text-destructive px-4 py-2 rounded-md mb-4">
            {error}
          </div>
        )}
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
          </div>
        ) : (
          <div>
            <div className="flex justify-between items-center mb-6">
              <h1 className="text-3xl font-bold tracking-tight">Prayer Requests</h1>
              {isAuthenticated && (
                <Button onClick={() => setIsDialogOpen(true)} className="bg-background text-foreground hover:bg-muted">
                  <Plus className="h-4 w-4 mr-2" />
                  New Prayer Request
                </Button>
              )}
            </div>

            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              {posts.map((post) => (
                <Card 
                  key={post.id} 
                  className="flex flex-col cursor-pointer hover:shadow-md transition-shadow"
                  onClick={() => setSelectedPost(post)}
                >
                  <CardHeader>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-2">
                        <span className="font-semibold">{post.author}</span>
                      </div>
                      <div className="flex items-center text-muted-foreground text-sm">
                        <Calendar className="h-4 w-4 mr-1" />
                        <time>{formatDate(post.date)}</time>
                      </div>
                    </div>
                    <h2 className="text-lg font-semibold mt-2">{post.title}</h2>
                  </CardHeader>
                  <CardContent>
                    <p className="text-muted-foreground">
                      {post.content.length > 150 
                        ? `${post.content.substring(0, 150)}...` 
                        : post.content}
                    </p>
                    {post.images && post.images.length > 0 && (
                      <div className="mt-4 grid grid-cols-3 gap-2">
                        {post.images.map((image, index) => (
                          <img
                            key={index}
                            src={image}
                            alt={`Prayer request image ${index + 1}`}
                            className="w-full h-24 object-cover rounded-md cursor-pointer hover:opacity-90 transition-opacity"
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(image, '_blank');
                            }}
                          />
                        ))}
                      </div>
                    )}
                    {post.tags.length > 0 && (
                      <div className="flex gap-2 mt-4 flex-wrap">
                        {post.tags.map((tag, index) => (
                          <span
                            key={index}
                            className="bg-muted px-2 py-1 rounded-md text-xs text-muted-foreground"
                          >
                            {tag}
                          </span>
                        ))}
                      </div>
                    )}
                  </CardContent>
                  <CardFooter className="mt-auto border-t pt-4">
                    <div className="flex items-center justify-between w-full text-muted-foreground">
                      {isAuthenticated ? (
                        <button 
                          className="flex items-center space-x-1 hover:text-foreground transition-colors"
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePrayerClick(post.id);
                          }}
                        >
                          <Heart className={`h-4 w-4 ${post.hasPrayed ? 'fill-primary text-primary' : ''}`} />
                          <span>{post.prayerCount}</span>
                        </button>
                      ) : (
                        <div className="text-sm text-muted-foreground">
                          <Link to="/login" className="text-primary hover:underline">
                            Login
                          </Link>
                          {' '}to pray for this request
                        </div>
                      )}
                      {isAuthenticated ? (
                        <button className="flex items-center space-x-1 hover:text-foreground transition-colors">
                          <MessageCircle className="h-4 w-4" />
                          <span>{post.comments.length}</span>
                        </button>
                      ) : (
                        <div className="text-sm text-muted-foreground">
                          <Link to="/login" className="text-primary hover:underline">
                            Login
                          </Link>
                          {' '}to comment on this request
                        </div>
                      )}
                    </div>
                  </CardFooter>
                </Card>
              ))}
            </div>
          </div>
        )}
      </div>

      {isAuthenticated && (
        <CreatePrayerRequest 
          open={isDialogOpen}
          onOpenChange={setIsDialogOpen}
          onSubmit={handleCreatePrayerRequest}
        />
      )}

      {selectedPost && (
        <PrayerRequestDetail
          post={selectedPost}
          open={!!selectedPost}
          onOpenChange={(open) => !open && setSelectedPost(null)}
          onPrayerClick={handlePrayerClick}
          onCommentSubmit={handleCommentSubmit}
        />
      )}
    </div>
  );
}
